import { useMutation } from 'urql';
import { gql } from 'urql';

export const SMS_MUTATION = gql`
  mutation ($tickets: [Int!]!, $message: String!) {
    sendSms(info: { ticketIds: $tickets, message: $message }) {
      id
      dbId
      error
      errorCode
      phoneNumber
      message
      userId
      ticketId
      eventId
    }
  }
`;

export interface ISMSVariables {
  message: string;
  tickets: number[];
}
export interface SmsResult {
  id: number | null;
  dbId: number;
  error: string | null;
  errorCode: number | null;
  phoneNumber: string;
  message: string;
  userId: number | null;
  ticketId: number | null;
  eventId: number | null;
}

export interface ISMSResponse {
  sendSms: SmsResult[];
}

export const useFetchSMS = () => {
  return useMutation<ISMSResponse, ISMSVariables>(SMS_MUTATION);
};
